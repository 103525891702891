<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3">
        <base-material-card color="success" inline icon="mdi-account" :title="$t('Cont')" class="px-5 py-3">
          <!-- <v-toolbar color="primary" dark>
            <v-toolbar-title>{{ $t("Profile") }}</v-toolbar-title>
          </v-toolbar> -->
          <!-- 1{{ libraryData }}2 -->
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-bank</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ libraryData.library.public_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t("Biblioteca") }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ accountName }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t("Nume") }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  color="primary"
                  @click="
                    dialog = true
                    accountNameNew = accountName
                  "
                >
                  mdi-pencil
                </v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-shield-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ accountUser }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t("Utilizator") }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-mail</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ accountEmail }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t("Email") }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="copyClipboard(accountEmail)">
                  <v-icon color="primary">mdi-content-copy</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-lock</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>********</v-list-item-title>
                <v-list-item-subtitle>{{ $t("Password") }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="primary" @click="dialogChange = true">mdi-pencil</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-key</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ sessionId }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t("Session Id") }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="copyClipboard(sessionId)">
                  <v-icon color="primary">mdi-content-copy</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <!-- <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-account-check</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ accountType }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t("Type") }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
            <!-- <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">mdi-apps</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ appType }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t("A") }}</v-list-item-subtitle>
              </v-list-item-content>
               <v-list-item-action>
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn class="ml-2" icon small v-on="on">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item v-for="typ in appTypes" :key="typ" @click="changeAppType(typ)">{{ typ }}</v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item> -->
            <!-- <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">vpn_key</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ accountUserKey }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t("userKey") }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
            <!-- <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">account_circle</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("deleteAccount") }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon @click="dialogDelete = true">delete</v-icon>
              </v-list-item-action>
            </v-list-item> -->
          </v-list>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent scrollable max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Change name") }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="formChangeName">
            <v-text-field
              v-model="accountNameNew"
              name="client"
              :label="$t('Name')"
              :rules="inputValidate"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="dialog = false">{{ $t("Cancel") }}</v-btn>
          <v-btn color="primary darken-1" @click="changeName">{{ $t("Change") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent scrollable max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Șterge cont") }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="formDelete" @submit.prevent>
            <v-text-field
              id="password"
              ref="password"
              v-model="password"
              append-icon="lock"
              name="password"
              :label="$t('Password')"
              type="password"
              :rules="inputValidate"
              @keydown.enter="deleteAccount"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="dialogDelete = false">{{ $t("Cancel") }}</v-btn>
          <v-btn color="primary darken-1" @click="deleteAccount">{{ $t("Delete") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogChange" persistent scrollable max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Schimbă parola") }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="formChange" @submit.prevent>
            <v-text-field
              ref="password"
              v-model="password"
              append-icon="mdi-lock"
              :label="$t('Parola curentă')"
              type="password"
              :rules="inputValidate"
              @keydown.enter="$refs.password1.focus()"
            ></v-text-field>
            <v-text-field
              ref="password1"
              v-model="password1"
              append-icon="mdi-lock"
              :label="$t('Parola nouă')"
              type="password"
              :rules="inputValidate"
              @keydown.enter="$refs.password2.focus()"
            ></v-text-field>
            <v-text-field
              ref="password2"
              v-model="password2"
              append-icon="mdi-lock"
              :label="$t('Confirmă parola')"
              type="password"
              :rules="inputValidatePassword"
              @keydown.enter="changePassword"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogChange = false">{{ $t("Anulează") }}</v-btn>
          <v-btn color="primary darken-1" @click="changePassword">{{ $t("Schimbă") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "../../plugins/axios"
import { EventBus } from "../../EventBus"
import md5 from "js-md5"
export default {
  data() {
    return {
      appTypes: [],
      dialog: false,
      dialogDelete: false,
      dialogChange: false,
      password: "",
      password1: "",
      password2: "",
      inputValidate: [],
      inputValidatePassword: [],
      accountNameNew: ""
    }
  },
  computed: {
    accountName: {
      get() {
        return this.$store.getters.accountName
      },
      set(val) {
        this.$store.commit("accountName", val)
      }
    },
    sessionId() {
      return this.$store.getters.sessionId
    },
    accountUserKey() {
      return this.$store.getters.accountUserKey
    },
    accountUser() {
      return this.$store.getters.accountUser
    },
    companyId() {
      return this.$store.getters.companyId
    },
    userId() {
      return this.$store.getters.accountId
    },
    accountType() {
      return this.$store.getters.accountType
    },
    appType() {
      return this.$store.getters.appType
    },
    libraryData() {
      this.$log("get librarydata ", this.$store.getters.libraryData)
      return this.$store.getters.libraryData
    },

    accountEmail: {
      get() {
        return this.$store.getters.accountEmail
      },
      set(val) {
        this.$store.commit("accountEmail", val)
      }
    }
  },
  created() {
    this.inputValidate.push(v => v.length > 0 || this.$t("required"))
    this.inputValidatePassword.push(v => v === this.password1 || this.$t("Parola nu este la fel cu cea nouă!"))
  },
  mounted() {
    this.$log("mounted ", this.companyId)
    axios.get('app_user?where={"_id":"' + this.userId + '"}').then(response => {
      this.$log("app_company reload", response)
      this.appTypes = response.data._items[0].settings.app_types
    })
  },
  methods: {
    copyClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text)
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea")
      textArea.value = text
      // Avoid scrolling to bottom
      textArea.style.top = "0"
      textArea.style.left = "0"
      textArea.style.position = "fixed"
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand("copy")
        //var msg = successful ? "successful" : "unsuccessful"
        //this.$log("Fallback: Copying text command was " + msg)
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err)
      }
      document.body.removeChild(textArea)
    },
    changeAppType(e) {
      this.$log("changeAppType ", e)
      this.$store.dispatch("changeappType", e)
    },
    changePassword() {
      if (this.$refs.formChange.validate()) {
        if (this.$store.getters.accountPass == md5(this.password)) {
          axios.get("app_user/" + this.$store.getters.accountId).then(res => {
            axios
              .patch(
                "app_user/" + this.$store.getters.accountId,
                { password: md5(this.password1) },
                {
                  headers: {
                    "If-Match": res.data._etag
                  }
                }
              )
              .then(resname => {
                this.$log("rename ", resname)
                if (resname.status == 200) {
                  EventBus.$emit("show-alert", { message: "Parola a fost schimbată, conectați-vă cu noua parolă." })
                  this.$store.commit("accountPass", md5(this.password1))
                  setTimeout(() => {
                    this.$store.dispatch("logOut")
                  }, 3000)
                }
                this.dialogChange = false
              })
          })
        } else {
          EventBus.$emit("show-alert", { message: this.$t("Parola nu este corectă!"), color: "error" })
        }
      }
    },
    deleteAccount() {
      if (this.$refs.formDelete.validate()) {
        axios.delete("/accounts/id/" + this.$store.getters.accountId + "?pass=" + md5(this.password)).then(response => {
          EventBus.$emit("show-alert", { message: response.data.msg, timeout: 3000 })
          if (response.data.code === 0) {
            this.$store.dispatch("clearAccountData")
          }
          this.dialogDelete = false
        })
      }
    },
    changeName() {
      if (this.$refs.formChangeName.validate()) {
        axios.get("app_user/" + this.$store.getters.accountId).then(res => {
          axios
            .patch(
              "app_user/" + this.$store.getters.accountId,
              { fullname: this.accountNameNew },
              {
                headers: {
                  "If-Match": res.data._etag
                }
              }
            )
            .then(resname => {
              this.$log("rename ", resname)
              if (resname.status == 200) {
                this.$store.commit("accountName", resname.data.fullname)
                this.accountName = resname.data.fullname
              }
              this.dialog = false
            })
        })
      }
    }
  }
}
</script>
<style scoped></style>
